import { GetterTree } from 'vuex';

import { RootState } from '@/store';

import { Brand } from "@/types/brand/Brand.interface";

import { State } from './state';

export type Getters = {
    getBrand(state: State): Brand;
}

export const getters: GetterTree<State, RootState> & Getters = {
    getBrand: (state) => state.brand,
};
