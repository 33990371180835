import { ActionTree, ActionContext } from 'vuex';

import { RootState, useStore } from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import { UserActionTypes } from './action-types';
import { UserMutationTypes } from './mutation-types';
import UserService from '@/service/user-service/UserService';
import { User, UserConsent } from '@/types/user/User.interface';
import { CartActionTypes } from '../cart/action-types';


type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [UserActionTypes.SET_USER](
        { commit }: AugmentedActionContext,
        value: string, // Obsolete in here but left as an example
    ): void
    [UserActionTypes.SET_USER_CONSENT](
        { commit }: AugmentedActionContext,
        value: string, // Obsolete in here but left as an example
    ): void
    [UserActionTypes.SET_USER_SOCIAL_REFID](
        { commit }: AugmentedActionContext,
        value: string, // Obsolete in here but left as an example
    ): void
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [UserActionTypes.SET_USER]({ commit }, value: string) {
        const userService = new UserService()
        const store = useStore()
        await userService.getUser(value).then((data: User) => {
            commit(UserMutationTypes.SET_USER, data);
            commit(UserMutationTypes.SET_USER_SOCIAL_REFID, data.socialReferenceId);
            if (data.socialReferenceId) {
                store.dispatch(UserActionTypes.SET_USER_CONSENT, data.socialReferenceId)
                const cartPayload = {
                    branchId: store.getters.getBrand.branchId,
                    socialReferenceId: store.getters.getUser.socialReferenceId,
                };
                store.dispatch(CartActionTypes.GET_CART, cartPayload);
            }
        })
    },
    async [UserActionTypes.SET_USER_CONSENT]({ commit }, value: string) {
        const userService = new UserService()
        userService.checkUserConsent(value).then((data: UserConsent) => {

            if (data.isConsent) {
                commit(UserMutationTypes.SET_USER_CONSENT, false);
            } else {
                commit(UserMutationTypes.SET_USER_CONSENT, true);
            }
        })
    },
    async [UserActionTypes.SET_USER_SOCIAL_REFID]({ commit }, value: string) {
        commit(UserMutationTypes.SET_USER_SOCIAL_REFID, value);
    }
};
