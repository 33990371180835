import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'

import moment from 'moment';

import PrimeVue from 'primevue/config';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Menubar from 'primevue/menubar';
import ProgressSpinner from 'primevue/progressspinner';
import InputText from 'primevue/inputtext';
import Sidebar from 'primevue/sidebar';
import Avatar from 'primevue/avatar';
import Tree from 'primevue/tree';
import Card from 'primevue/card';
import Galleria from 'primevue/galleria';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import Panel from 'primevue/panel';
import RadioButton from 'primevue/radiobutton';
import Message from 'primevue/message';
import BlockUI from 'primevue/blockui';
import Menu from 'primevue/menu';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import Checkbox from "primevue/checkbox"
import AutoComplete from 'primevue/autocomplete';


import "./main.css"
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';


const app = createApp(App);

app.use(PrimeVue);
app.component('Button', Button);
app.component('Dialog', Dialog);
app.component('Menubar', Menubar);
app.component('ProgressSpinner', ProgressSpinner);
app.component('InputText', InputText);
app.component('Sidebar', Sidebar);
app.component('Avatar', Avatar);
app.component('Tree', Tree);
app.component('Card', Card);
app.component('Galleria', Galleria);
app.component('Badge', Badge);
app.directive('badge', BadgeDirective);
app.component('Panel', Panel);
app.component('RadioButton', RadioButton);
app.component('Message', Message);
app.component('BlockUI', BlockUI);
app.component('Menu', Menu);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Dropdown', Dropdown);
app.component('Checkbox', Checkbox)
app.component('Textarea', Textarea)
app.component('AutoComplete', AutoComplete)

moment.locale('th');


app.config.globalProperties.$filters = {
    formatNumber(number: number) {
        return new Intl.NumberFormat("en-gb", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
        }).format(number);
    }
}
app.config.globalProperties.$moment = moment;



app.use(store).use(router).mount('#app')
