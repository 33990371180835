import { MutationTree } from 'vuex';
import { State } from './state';
import { CategoryMutationTypes } from './mutation-types';
import { Category, CategoryBreadcrumb } from '@/types/category/Category.interface';

export type Mutations<S = State> = {
    [CategoryMutationTypes.SET_CATEGORY_LIST](state: S, payload: Category[]): void;
    [CategoryMutationTypes.SET_CATEGORY_SELECTED](state: S, payload: Category[]): void;
    [CategoryMutationTypes.SET_CATEGORY_BREADCRUMB](state: S, payload: CategoryBreadcrumb[]): void;
}

export const mutations: MutationTree<State> & Mutations = {
    [CategoryMutationTypes.SET_CATEGORY_LIST](state: State, categoryList: Category[]) {
        state.categoryList = categoryList;
    },
    [CategoryMutationTypes.SET_CATEGORY_SELECTED](state: State, categorySelected: Category[]) {
        state.categorySelected = categorySelected;
    },
    [CategoryMutationTypes.SET_CATEGORY_BREADCRUMB](state: State, categoryBreadcrumb: CategoryBreadcrumb[]) {
        state.categoryBreadcrumb = categoryBreadcrumb;
    },
};
