import { GetterTree } from 'vuex';

import { RootState } from '@/store';

import { State } from './state';

export type Getters = {
    getPlatform(state: State): number;
    getLoading(state: State): boolean;
    getSaleChannel(state: State): number;
    getComponentLoading(state: State): boolean;
    getIsInProductDetail(state: State): boolean;
    getSessionId(state: State): string
}

export const getters: GetterTree<State, RootState> & Getters = {
    getPlatform: (state) => state.platform,
    getLoading: (state) => state.loading,
    getSaleChannel: (state) => state.saleChanel,
    getComponentLoading: (state) => state.componentLoading,
    getIsInProductDetail: (state) => state.isNotOpenInProductDetail,
    getSessionId: (state) => state.sessionId,
};
