import { ActionTree, ActionContext } from 'vuex';

import { RootState, useStore } from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import { CategoryMutationTypes } from './mutation-types';
import { CategoryActionTypes } from './action-types';
import { Category, CategoryBreadcrumb } from '@/types/category/Category.interface';
import CategoryService from '@/service/category-service/CategoryService';

export interface CateGoryStorePayload {
    category: string,
    categoryList: Category[]
}

export interface CateGoryListStorePayload {
    brandId: number,
    category: string,
}

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [CategoryActionTypes.SET_CATEGORY_LIST](
        { commit }: AugmentedActionContext,
        payload: CateGoryListStorePayload
    ): void;
    [CategoryActionTypes.SET_CATEGORY_SELECTED](
        { commit }: AugmentedActionContext,
        category: Category
    ): void;
    [CategoryActionTypes.SET_CATEGORY_CONDITION](
        { commit }: AugmentedActionContext,
        payload: CateGoryStorePayload
    ): void;
    [CategoryActionTypes.GET_CATEGORY_BY_URLKEY](
        { commit }: AugmentedActionContext,
        payload: CateGoryStorePayload
    ): void;
    [CategoryActionTypes.GET_PARENT_CATEGORY_BY_URLKEY](
        { commit }: AugmentedActionContext,
        payload: CateGoryStorePayload
    ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [CategoryActionTypes.SET_CATEGORY_LIST]({ commit }, dispatchPayload: CateGoryListStorePayload) {
        const categoryService = new CategoryService()
        const store = useStore()
        await categoryService.getCategory(dispatchPayload.brandId).then(async data => {
            commit(CategoryMutationTypes.SET_CATEGORY_LIST, data)
            const payload = {
                category: dispatchPayload.category,
                categoryList: data
            } as CateGoryStorePayload

            store.dispatch(CategoryActionTypes.SET_CATEGORY_CONDITION, payload as CateGoryStorePayload)
        })
    },
    async [CategoryActionTypes.SET_CATEGORY_SELECTED]({ commit }, category: Category) {
        const defaultCat = [{
            categoryList: [],
            code: category.code,
            id: category.id,
            name: "ทั้งหมด",
            urlKey: category.urlKey
        }] as Category[]

        commit(CategoryMutationTypes.SET_CATEGORY_SELECTED, defaultCat.concat(category.categoryList))
    },
    async [CategoryActionTypes.SET_CATEGORY_CONDITION]({ commit, state }, payload: CateGoryStorePayload) {
        const store = useStore()

        if (!payload.category || payload.category == "All") {

            const defaultCat = [{
                categoryList: [],
                code: "",
                id: 0,
                name: "ทั้งหมด",
                urlKey: "All"
            }] as Category[]

            commit(CategoryMutationTypes.SET_CATEGORY_SELECTED, defaultCat.concat(payload.categoryList))
            commit(CategoryMutationTypes.SET_CATEGORY_BREADCRUMB, [])
            let categoryBreadcrumb = [] as CategoryBreadcrumb[]
            categoryBreadcrumb = state.categoryBreadcrumb
            categoryBreadcrumb.push({ name: store.state.brand.brand?.name, urlKey: "" });
            categoryBreadcrumb.push({ name: "ทั้งหมด", urlKey: "All" });
            commit(CategoryMutationTypes.SET_CATEGORY_BREADCRUMB, categoryBreadcrumb)
        } else {
            store.dispatch(CategoryActionTypes.GET_CATEGORY_BY_URLKEY, payload as CateGoryStorePayload)
            store.dispatch(CategoryActionTypes.GET_PARENT_CATEGORY_BY_URLKEY, payload as CateGoryStorePayload)
        }
    },
    async [CategoryActionTypes.GET_CATEGORY_BY_URLKEY]({ commit, state }, payload: CateGoryStorePayload) {
        const store = useStore()
        payload.categoryList.forEach(cat1 => {
            if (cat1.urlKey == payload.category) {

                if (cat1.categoryList.length > 0) {
                    store.dispatch(CategoryActionTypes.SET_CATEGORY_SELECTED, cat1)
                } else {
                    const defaultCat = [{
                        categoryList: [],
                        code: "",
                        id: 0,
                        name: "ทั้งหมด",
                        urlKey: ""
                    }] as Category[]
                    commit(CategoryMutationTypes.SET_CATEGORY_SELECTED, defaultCat.concat(state.categoryList))
                }
            } else {
                cat1.categoryList.forEach(cat2 => {
                    if (cat2.urlKey == payload.category) {
                        if (cat2.categoryList.length > 0) {
                            store.dispatch(CategoryActionTypes.SET_CATEGORY_SELECTED, cat2)
                        } else {
                            store.dispatch(CategoryActionTypes.SET_CATEGORY_SELECTED, cat1)
                        }
                    } else {
                        cat2.categoryList.forEach(cat3 => {
                            if (cat3.urlKey == payload.category) {
                                if (cat3.categoryList.length > 0) {
                                    store.dispatch(CategoryActionTypes.SET_CATEGORY_SELECTED, cat3)
                                } else {
                                    store.dispatch(CategoryActionTypes.SET_CATEGORY_SELECTED, cat2)
                                }
                            } else {
                                cat3.categoryList.forEach(cat4 => {
                                    if (cat4.urlKey == payload.category) {
                                        if (cat4.categoryList.length > 0) {
                                            store.dispatch(CategoryActionTypes.SET_CATEGORY_SELECTED, cat4)
                                        } else {
                                            store.dispatch(CategoryActionTypes.SET_CATEGORY_SELECTED, cat3)
                                        }
                                    }
                                });
                            }

                        });
                    }
                });
            }
        });
    },
    async [CategoryActionTypes.GET_PARENT_CATEGORY_BY_URLKEY]({ commit, state }, payload: CateGoryStorePayload) {
        const store = useStore()
        commit(CategoryMutationTypes.SET_CATEGORY_BREADCRUMB, [])
        let categoryBreadcrumb = [] as CategoryBreadcrumb[]
        categoryBreadcrumb = state.categoryBreadcrumb
        categoryBreadcrumb.push({ name: store.state.brand.brand?.name, urlKey: "" });
        categoryBreadcrumb.push({ name: "ทั้งหมด", urlKey: "All" });
        commit(CategoryMutationTypes.SET_CATEGORY_BREADCRUMB, categoryBreadcrumb)
        payload.categoryList.forEach(cat1 => {
            if (cat1.urlKey == payload.category) {
                categoryBreadcrumb.push({ name: cat1.name, urlKey: cat1.urlKey });
                commit(CategoryMutationTypes.SET_CATEGORY_BREADCRUMB, categoryBreadcrumb)
            } else {
                cat1.categoryList.forEach(cat2 => {
                    if (cat2.urlKey == payload.category) {
                        categoryBreadcrumb.push({ name: cat1.name, urlKey: cat1.urlKey });
                        categoryBreadcrumb.push({ name: cat2.name, urlKey: cat2.urlKey });
                        commit(CategoryMutationTypes.SET_CATEGORY_BREADCRUMB, categoryBreadcrumb)
                    } else {
                        cat2.categoryList.forEach(cat3 => {
                            if (cat3.urlKey == payload.category) {
                                categoryBreadcrumb.push({ name: cat1.name, urlKey: cat1.urlKey });
                                categoryBreadcrumb.push({ name: cat2.name, urlKey: cat2.urlKey });
                                categoryBreadcrumb.push({ name: cat3.name, urlKey: cat3.urlKey });
                                commit(CategoryMutationTypes.SET_CATEGORY_BREADCRUMB, categoryBreadcrumb)
                            } else {
                                cat3.categoryList.forEach(cat4 => {
                                    if (cat4.urlKey == payload.category) {
                                        categoryBreadcrumb.push({ name: cat1.name, urlKey: cat1.urlKey });
                                        categoryBreadcrumb.push({ name: cat2.name, urlKey: cat2.urlKey });
                                        categoryBreadcrumb.push({ name: cat3.name, urlKey: cat3.urlKey });
                                        categoryBreadcrumb.push({ name: cat4.name, urlKey: cat4.urlKey });
                                        commit(CategoryMutationTypes.SET_CATEGORY_BREADCRUMB, categoryBreadcrumb)
                                    }
                                });
                            }

                        });
                    }
                });
            }
        });
    },

};
