import { ActionTree, ActionContext } from 'vuex';

import { RootState, useStore } from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import { GlobalMutationTypes } from './mutation-types';
import { GlobalActionTypes } from './action-types';
import { useRoute } from 'vue-router';
import { BrandActionTypes } from '../brand/action-types';
import { TransactionActionTypes } from '../transaction/action-types';
import { UserActionTypes } from '../user/action-types';

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [GlobalActionTypes.SET_LOADING](
        { commit }: AugmentedActionContext,
        value: boolean, // Obsolete in here but left as an example
    ): void
    [GlobalActionTypes.SET_COMPONENT_LOADING](
        { commit }: AugmentedActionContext,
        value: boolean, // Obsolete in here but left as an example
    ): void
    [GlobalActionTypes.SET_PLATFORM](
        { commit }: AugmentedActionContext
    ): void
    [GlobalActionTypes.GET_APP_DATA](
        { commit }: AugmentedActionContext,
        isLine: boolean
    ): void
    [GlobalActionTypes.SET_TRANSACTION_PLATFORM](
        { commit }: AugmentedActionContext,
        value: number, // Obsolete in here but left as an example
    ): void
    [GlobalActionTypes.SET_IS_IN_PRODUCT_DETAIL](
        { commit }: AugmentedActionContext,
        value: boolean, // Obsolete in here but left as an example
    ): void
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [GlobalActionTypes.SET_LOADING]({ commit }, value: boolean) {
        commit(GlobalMutationTypes.SET_LOADING, value);

    },
    async [GlobalActionTypes.SET_COMPONENT_LOADING]({ commit }, value: boolean) {
        commit(GlobalMutationTypes.SET_COMPONENT_LOADING, value);

    },
    async [GlobalActionTypes.SET_PLATFORM]({ commit }) {
        const route = useRoute()
        const UA = navigator.userAgent || navigator.vendor
        console.log("route.params", route.params);
        console.log("UA", UA);
        
        if (route.params.initialId?.length > 10 && route.params.initialId?.length <= 15) {
            // refer to facebook messenger platform 
            commit(GlobalMutationTypes.SET_PLATFORM, 2);
            commit(GlobalMutationTypes.SET_SALECHANNEL, 1);
        } else if (route.params.initialId?.length > 10 && route.params.initialId?.length <= 17) {
            // refer to instragram platform 
            commit(GlobalMutationTypes.SET_PLATFORM, 5);
            commit(GlobalMutationTypes.SET_SALECHANNEL, 6);
        } else if (UA.indexOf("Line/") > -1 || route.params.initialId?.length == 10) {
            // refer to line liff platform 
            commit(GlobalMutationTypes.SET_PLATFORM, 1);
            commit(GlobalMutationTypes.SET_SALECHANNEL, 3);
        } else {
            // refer to other desktop platform 
            commit(GlobalMutationTypes.SET_PLATFORM, 0);
            commit(GlobalMutationTypes.SET_SALECHANNEL, 0);
        }
    },
    async [GlobalActionTypes.SET_TRANSACTION_PLATFORM]({ commit }, value: number) {
        commit(GlobalMutationTypes.SET_PLATFORM, value);

    },
    async [GlobalActionTypes.GET_APP_DATA]({ commit, state }, isLine: boolean) {
        const store = useStore();
        const route = useRoute();
        const sessionId = route.query.sessionId?.toString()
        let initialId = ""

        // isLine == true use when is in line liff
        // ถ้าใน line จะเข้า route @/views/pages/line-redirect/Index.vue ก่อน
        // ตอนอยู่ใน line iff ต้องเอา  initialId มาแล้วยิง api ไป get lineliffId ของ brand นั้นมาก่อนแล้ว redirect ไปหา line liff ของ brand นั้นๆ
        if (!isLine) {
            initialId = route.params.initialId.toString()
        } else {
            const queryString = decodeURIComponent(window.location.search);
            const params = new URLSearchParams(queryString);
            const searchParam = params.get("liff.state");
            if (searchParam) {
                initialId = searchParam.split("/")[1];
            }
        }

        store.dispatch(GlobalActionTypes.SET_LOADING, true)

        if (sessionId) {
            state.sessionId = sessionId
        }

        //refer to is not order page
        if (initialId && initialId.length >= 10 && initialId.length <= 17) {
            await store.dispatch(GlobalActionTypes.SET_PLATFORM)
            await store.dispatch(BrandActionTypes.GET_BRAND_SOCIAL_CONFIG, initialId)
            if (sessionId) {
                store.dispatch(UserActionTypes.SET_USER, sessionId)
            } else {
                store.dispatch(GlobalActionTypes.SET_LOADING, false);
            }
        }
        else {
            //refer to  is order page
            await store.dispatch(TransactionActionTypes.SET_TRANSACTION, initialId)
            if (sessionId) {
                store.dispatch(UserActionTypes.SET_USER, sessionId)
            } else {
                store.dispatch(GlobalActionTypes.SET_LOADING, false);
            }
        }

    },
    async [GlobalActionTypes.SET_IS_IN_PRODUCT_DETAIL]({ commit }, value: boolean) {
        commit(GlobalMutationTypes.SET_IS_IN_PRODUCT_DETAIL, value);
    },
};
