import { MutationTree } from 'vuex';
import { Brand } from "@/types/brand/Brand.interface";
import { State } from './state';
import { BrandMutationTypes } from './mutation-types';

export type Mutations<S = State> = {
    [BrandMutationTypes.SET_BRAND](state: S, payload: Brand): void;
}

export const mutations: MutationTree<State> & Mutations = {
    [BrandMutationTypes.SET_BRAND](state: State, brand: Brand) {
        state.brand = brand;
    },
};
