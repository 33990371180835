import { GetterTree } from 'vuex';

import { RootState } from '@/store';

import { State } from './state';
import { Cart } from '@/types/cart/Cart.interface';

export type Getters = {
    getQuantity(state: State): number | 0;
    getCart(state: State): Cart
    getIsShowCartPolicy(state: State): boolean
    getIsFreeProductDisableFlag(state: State): boolean
    getRefreshCart(state: State): boolean
}

export const getters: GetterTree<State, RootState> & Getters = {
    getQuantity: (state) => {
        let quantity = 0
        state.cart.products.forEach(element => {
            quantity += element.quantity
        });
        return quantity
    },
    getCart: (state) => {
        return state.cart
    },
    getIsShowCartPolicy: (state) => {
        return state.isShowCartPolicy
    },
    getIsFreeProductDisableFlag: (state) => {
        return state.freeProductDisableFlage
    },
    getRefreshCart: (state) => {
        return state.refreshCart
    },
};
