
import { Category, CategoryBreadcrumb } from "@/types/category/Category.interface";

export type State = {
    categoryList: Category[] | [],
    categorySelected: Category[] | [],
    categoryBreadcrumb: CategoryBreadcrumb[] | []
}

export const state: State = {
    categoryList: [],
    categorySelected: [
        {
            categoryList: [],
            code: "",
            id: 0,
            name: "ทั้งหมด",
            urlKey: "All"
        }
    ],
    categoryBreadcrumb: []
};
