import { MutationTree } from 'vuex';
import { State } from './state';
import { TransactionMutationTypes } from './mutation-types';
import { TransactionDetail } from '@/types/transaction/TransactionDetail.interface';
import { BuyerInformation } from '@/types/transaction/TransactionAddress.interface';

export type Mutations<S = State> = {
    [TransactionMutationTypes.SET_TRANSACTION](state: S, payload: TransactionDetail): void;
    [TransactionMutationTypes.SET_BUYER_INFO](state: S, payload: BuyerInformation): void;
}

export const mutations: MutationTree<State> & Mutations = {
    [TransactionMutationTypes.SET_TRANSACTION](state: State, value: TransactionDetail) {
        state.transaction = value;
    },
    [TransactionMutationTypes.SET_BUYER_INFO](state: State, value: BuyerInformation) {
        state.transaction.buyerInfo = value;
    },

};
