import { ActionTree, ActionContext } from 'vuex';

import { RootState, useStore } from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import { CartActionTypes } from './action-types';
import CartService from '@/service/cart-service/CartService';
import { CartMutationTypes } from './mutation-types';
import { Cart } from '@/types/cart/Cart.interface';
import { GlobalActionTypes } from '../global/action-types';
import { nextTick } from 'vue-demi';

export interface CartStorePayload {
    branchId: number,
    socialReferenceId: string
}

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [CartActionTypes.GET_CART](
        { commit }: AugmentedActionContext,
        payload: CartStorePayload
    ): void;
    [CartActionTypes.SET_CART](
        { commit }: AugmentedActionContext,
        cart: Cart
    ): void;
    [CartActionTypes.SET_IS_SHOW_POLICY](
        { commit }: AugmentedActionContext,
        value: boolean
    ): void;
    [CartActionTypes.ADD_QUANTITY](
        { commit }: AugmentedActionContext,
        index: number
    ): void;
    [CartActionTypes.REMOVE_QUANTITY](
        { commit }: AugmentedActionContext,
        index: number
    ): void;
    [CartActionTypes.REMOVE_ALL_PRODUCT](
        { commit }: AugmentedActionContext,
        index: number
    ): void;
    [CartActionTypes.SET_IS_FREE_PRODUCT_DISABLED_FLAG](
        { commit }: AugmentedActionContext,
        value: boolean
    ): void;
    [CartActionTypes.SER_REFRESH_CART](
        { commit }: AugmentedActionContext,
        value: boolean
    ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [CartActionTypes.GET_CART]({ commit, state }, payload: CartStorePayload) {
        if (payload.socialReferenceId) {
            const cartService = new CartService()
            const store = useStore()
            await cartService.getCartDetail(payload.branchId, payload.socialReferenceId).then(res => {
                commit(CartMutationTypes.SET_CART, res)
                store.dispatch(GlobalActionTypes.SET_LOADING, false);
                store.dispatch(CartActionTypes.SER_REFRESH_CART, false);
                nextTick(() => {
                    store.dispatch(CartActionTypes.SER_REFRESH_CART, true);
                })
            })
        }
    },
    async [CartActionTypes.SET_CART]({ commit }, cart: Cart) {
        commit(CartMutationTypes.SET_CART, cart)
    },
    async [CartActionTypes.SET_IS_SHOW_POLICY]({ commit }, value: boolean) {
        commit(CartMutationTypes.SET_SHOW_CART_POLICY, value)
    },
    async [CartActionTypes.ADD_QUANTITY]({ commit, state }, index: number) {
        state.cart.products[index].quantity = state.cart.products[index].quantity + 1
    },
    async [CartActionTypes.REMOVE_QUANTITY]({ commit, state }, index: number) {
        state.cart.products[index].quantity = state.cart.products[index].quantity - 1
    },
    async [CartActionTypes.REMOVE_ALL_PRODUCT]({ commit, state }, index: number) {
        state.cart.products.splice(index, 1)
    },
    async [CartActionTypes.SET_IS_FREE_PRODUCT_DISABLED_FLAG]({ commit }, value: boolean) {
        commit(CartMutationTypes.SET_FREE_PRODUCT_DISABLE_FLAG, value)
    },
    async [CartActionTypes.SER_REFRESH_CART]({ commit }, value: boolean) {
        commit(CartMutationTypes.SER_REFRESH_CART, value)
    },

};
