
import ResponseData from "@/types/response/Response.interface"
import axios from "../instance/AxiosInstance"
import { User, UserConsent, UserResponse, UserConsentResponse, UserSocial, UserSocialResponse } from "@/types/user/User.interface"

export default class UserService {
    async getUser(sessionId?: string): Promise<User> {
        return await axios.get('/user/profile/' + sessionId)
            .then((res: ResponseData<UserResponse>) => {
                if (res.data.result == 1) {
                    return res.data.detail as User
                } else {
                    throw new Error(res.data.message);
                }
            }).catch(error => {
                if (error.response) {
                    throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })
    }
    async checkUserConsent(socialReferenceId: string): Promise<UserConsent> {
        return await axios.get('/user/consent/' + socialReferenceId)
            .then((res: ResponseData<UserConsentResponse>) => {
                if (res.data.result == 1) {
                    return res.data.detail as UserConsent
                } else {
                    throw new Error(res.data.message);
                }
            }).catch(error => {
                if (error.response) {
                    throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })
    }
    async getUserSocial(data: Record<string, any>): Promise<UserSocial> {
        return await axios({
            method: 'post',
            url: '/User/Social',
            baseURL: process.env.VUE_APP_MODE === 'DEV' ? 'https://dev-dbot-message-api.d3-dots.com/api' : 'https://uat-dbot-message-api.d-dots.com/api',
            data: data,
        }).then((res: ResponseData<UserSocialResponse>) => {
            if (res.data.result == 1) {
                return res.data.detail as UserSocial
            } else {
                throw new Error(res.data.message);
            }
        }).catch(error => {
            if (error.response) {
                throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
            } else {
                throw error
            }
        })
    }

}
