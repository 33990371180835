import { GetterTree } from 'vuex';

import { RootState } from '@/store';


import { State } from './state';
import { Category, CategoryBreadcrumb } from '@/types/category/Category.interface';

export type Getters = {
    getCategoryList(state: State): Category[] | [];
    getCategorySelected(state: State): Category[] | [];
    getCategoryBreadCrumb(state: State): CategoryBreadcrumb[] | [];
}

export const getters: GetterTree<State, RootState> & Getters = {
    getCategoryList: (state) => state.categoryList,
    getCategorySelected: (state) => state.categorySelected,
    getCategoryBreadCrumb: (state) => state.categoryBreadcrumb,
};
