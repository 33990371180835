import { MutationTree } from 'vuex';
import { State } from './state';
import { UserMutationTypes } from './mutation-types';
import { User, UserConsent } from '@/types/user/User.interface';
import { BuyerInformation } from '@/types/transaction/TransactionAddress.interface';

export type Mutations<S = State> = {
    [UserMutationTypes.SET_USER](state: S, payload: User): void;
    [UserMutationTypes.SET_USER_CONSENT](state: S, payload: boolean): void;
    [UserMutationTypes.SET_USER_SOCIAL_REFID](state: S, payload: string): void;

}

export const mutations: MutationTree<State> & Mutations = {
    [UserMutationTypes.SET_USER](state: State, value: User) {
        state.user = value;
    },
    [UserMutationTypes.SET_USER_CONSENT](state: State, value: boolean) {
        state.isUserNeedConsent = value;
    },
    [UserMutationTypes.SET_USER_SOCIAL_REFID](state: State, value: string) {
        state.user.socialReferenceId = value;
    },


};
