import { MutationTree } from 'vuex';
import { State } from './state';
import { GlobalMutationTypes } from './mutation-types';

export type Mutations<S = State> = {
    [GlobalMutationTypes.SET_LOADING](state: S, payload: boolean): void;
    [GlobalMutationTypes.SET_COMPONENT_LOADING](state: S, payload: boolean): void;
    [GlobalMutationTypes.SET_PLATFORM](state: S, payload: number): void;
    [GlobalMutationTypes.SET_SALECHANNEL](state: S, payload: number): void;
    [GlobalMutationTypes.SET_IS_IN_PRODUCT_DETAIL](state: S, payload: boolean): void;
    [GlobalMutationTypes.SET_SESSION_ID](state: S, payload: string): void;
}

export const mutations: MutationTree<State> & Mutations = {
    [GlobalMutationTypes.SET_LOADING](state: State, value: boolean) {
        state.loading = value;
    },
    [GlobalMutationTypes.SET_COMPONENT_LOADING](state: State, value: boolean) {
        console.log("SET_COMPONENT_LOADING", value);
        
        state.componentLoading = value;
    },
    [GlobalMutationTypes.SET_PLATFORM](state: State, value: number) {

        state.platform = value
    },
    [GlobalMutationTypes.SET_SALECHANNEL](state: State, value: number) {
        state.saleChanel = value
    },
    [GlobalMutationTypes.SET_IS_IN_PRODUCT_DETAIL](state: State, value: boolean) {
        state.isNotOpenInProductDetail = value;
    },
    [GlobalMutationTypes.SET_SESSION_ID](state: State, value: string) {
        state.sessionId = value;
    },
};
