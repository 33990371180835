import { ActionTree, ActionContext } from 'vuex';

import { RootState, useStore } from '@/store';

import { State } from './state';
import { Mutations } from './mutations';
import { BrandMutationTypes } from './mutation-types';
import { BrandActionTypes } from './action-types';
import { Brand, BrandConfig } from '@/types/brand/Brand.interface';
import BrandService from '@/service/brand-service/BrandService';
import { CategoryActionTypes } from '../category/action-types';
import { GlobalActionTypes } from '../global/action-types';
import { useRoute } from 'vue-router';
import { CateGoryListStorePayload } from '../category/actions';

type AugmentedActionContext = {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1],
    ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, RootState>, 'commit'>

export interface Actions {
    [BrandActionTypes.GET_BRAND_SOCIAL_CONFIG](
        { commit }: AugmentedActionContext,
        initialId: string
    ): void;
    [BrandActionTypes.GET_BRAND_CONFIG](
        { commit }: AugmentedActionContext,
        brandId: number
    ): void;
}

export const actions: ActionTree<State, RootState> & Actions = {
    async [BrandActionTypes.GET_BRAND_SOCIAL_CONFIG]({ commit }, initialId: string) {
        const brandService = new BrandService()
        const store = useStore();
        const route = useRoute();
        await brandService.getBrandDetail(initialId, store.getters.getPlatform).then(async (data: Brand) => {
            commit(BrandMutationTypes.SET_BRAND, data);
            let category = ""
            if (route && route.params.category) {
                category = route.params.category?.toString()
            } else {
                category = "All"
            }
            const payload = {
                brandId: data.brandId,
                category: category
            } as CateGoryListStorePayload
            store.dispatch(CategoryActionTypes.SET_CATEGORY_LIST, payload)
            // store.dispatch(GlobalActionTypes.SET_LOADING, false);

            document.title = data.name;
            const link = document.querySelector("link[rel*='icon']") || document.createElement("link");
            link.setAttribute(
                "type",
                "image/x-icon"
            );
            link.setAttribute(
                "rel",
                "shortcut icon"
            );
            link.setAttribute(
                "href",
                data.favicon
            );
            document.getElementsByTagName("head")[0].appendChild(link);
        })
    },
    async [BrandActionTypes.GET_BRAND_CONFIG]({ commit }, brandId: number) {
        const brandService = new BrandService()
        const store = useStore();
        await brandService.getBrandConfig(brandId).then(async (data: BrandConfig) => {
            if (store.getters.getPlatform == 2) {
                store.dispatch(BrandActionTypes.GET_BRAND_SOCIAL_CONFIG, data.pageId)
            } else if (store.getters.getPlatform == 5) {
                store.dispatch(BrandActionTypes.GET_BRAND_SOCIAL_CONFIG, data.igId)
            } else {
                store.dispatch(BrandActionTypes.GET_BRAND_SOCIAL_CONFIG, data.lineOAId)
            }
        })
    },
};
