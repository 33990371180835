
export type State = {
    loading: boolean,
    platform: number,
    saleChanel: number,
    componentLoading: boolean,
    isNotOpenInProductDetail: boolean,
    sessionId: string
}

export const state: State = {
    loading: true,
    platform: 0,
    saleChanel: 0,
    componentLoading: false,
    isNotOpenInProductDetail: false,
    sessionId: ""
};
