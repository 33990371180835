
import { Cart, CartResponse, Order, OrderResponse, UserCart } from "@/types/cart/Cart.interface";
import ResponseData from "@/types/response/Response.interface"
import axios from "../instance/AxiosInstance"

export default class CartService {
    async getCartDetail(branchId: number, socialReferenceId: string): Promise<Cart> {
        return await axios.get(`/Cart/usercart/${branchId}/${socialReferenceId}`)
            .then((res: ResponseData<CartResponse>) => {
                if (res.data.result == 1) {
                    return res.data.detail as Cart
                } else {
                    throw new Error(res.data.message);
                }
            }).catch(error => {
                if (error.response) {
                    throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })
    }
    async onSaveCart(cartDetail: UserCart): Promise<Cart> {
        return await axios.post(`/Cart/updateusercartchatbot`, cartDetail)
            .then((res: ResponseData<CartResponse>) => {
                if (res.data.result == 1) {
                    return res.data.detail as Cart
                } else {
                    throw new Error(res.data.message)
                }
            }).catch((error) => {
                if (error.response) {
                    throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })
    }
    async onSaveOrder(cartDetail: UserCart, note: string): Promise<Order> {
        cartDetail.isSeller = false
        return await axios.post(`/transaction/save`, { cart: cartDetail, note: note })
            .then((res: ResponseData<OrderResponse>) => {
                if (res.data.result == 1) {
                    return res.data.detail as Order
                } else {
                    throw new Error(res.data.message);
                }
            }).catch(error => {
                if (error.response) {
                    throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })
    }



}
