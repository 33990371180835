export enum CartActionTypes {
    SET_CART = 'SET_CART',
    GET_CART = 'GET_CART',
    SET_IS_SHOW_POLICY = 'SET_IS_SHOW_POLICY',
    ADD_QUANTITY = 'ADD_QUANTITY',
    REMOVE_QUANTITY = 'REMOVE_QUANTITY',
    REMOVE_ALL_PRODUCT = 'REMOVE_ALL_PRODUCT',
    SET_IS_FREE_PRODUCT_DISABLED_FLAG = 'SET_IS_FREE_PRODUCT_DISABLED_FLAG',
    SER_REFRESH_CART = 'SER_REFRESH_CART'
}
