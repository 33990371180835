
import ResponseData from "@/types/response/Response.interface"
import axios from "../instance/AxiosInstance"

import { Brand, BrandConfig, BrandConfigResponse, BrandResponse } from "@/types/brand/Brand.interface";
import { useStore } from "@/store";
import { GlobalActionTypes } from "@/store/modules/global/action-types";

export default class BrandService {
    async getBrandDetail(initialId: string, socialRef: number): Promise<Brand> {
        // return await axios.get(`/brand/social/${initialId}/${socialRef}`)
        return await axios.get(`/brand/social/${initialId}/1`)
            .then((res: ResponseData<BrandResponse>) => {
                if (res.data.result == 1) {
                    return res.data.detail as Brand
                } else {
                    throw new Error(res.data.message);
                }
            }).catch(error => {
                if (error.response) {
                    throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })
    }
    async getBrandConfig(brandId: number): Promise<BrandConfig> {
        return await axios.get(`/brand/${brandId}/config`)
            .then((res: ResponseData<BrandConfigResponse>) => {
                if (res.data.result == 1) {
                    return res.data.detail as BrandConfig
                } else {
                    throw new Error(res.data.message);
                }
            }).catch(error => {
                if (error.response) {
                    throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })
    }

}
