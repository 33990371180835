import { MutationTree } from 'vuex';
import { State } from './state';
import { CartMutationTypes } from './mutation-types';
import { Cart } from '@/types/cart/Cart.interface';

export type Mutations<S = State> = {
    [CartMutationTypes.SET_CART](state: S, payload: Cart): void;
    [CartMutationTypes.SET_SHOW_CART_POLICY](state: S, payload: boolean): void;
    [CartMutationTypes.SET_FREE_PRODUCT_DISABLE_FLAG](state: S, payload: boolean): void;
    [CartMutationTypes.SER_REFRESH_CART](state: S, payload: boolean): void;
}

export const mutations: MutationTree<State> & Mutations = {
    [CartMutationTypes.SET_CART](state: State, cart: Cart) {
        state.cart = cart;
    },
    [CartMutationTypes.SET_SHOW_CART_POLICY](state: State, isShowCartPolicy: boolean) {
        state.isShowCartPolicy = isShowCartPolicy;
    },
    [CartMutationTypes.SET_FREE_PRODUCT_DISABLE_FLAG](state: State, flag: boolean) {
        state.freeProductDisableFlage = flag;
    },
    [CartMutationTypes.SER_REFRESH_CART](state: State, flag: boolean) {
        state.refreshCart = flag;
    },
};
