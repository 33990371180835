import { GetterTree } from 'vuex';

import { RootState } from '@/store';

import { State } from './state';
import { User } from '@/types/user/User.interface';

export type Getters = {
    getUser(state: State): User;
    getUserConsent(state: State): boolean;
    getUserSocialREFID(state: State): string;
}

export const getters: GetterTree<State, RootState> & Getters = {
    getUser: (state) => state.user,
    getUserConsent: (state) => state.isUserNeedConsent,
    getUserSocialREFID: (state) => state.user.socialReferenceId,
};
