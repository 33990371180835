import { GetterTree } from 'vuex';

import { RootState } from '@/store';

import { State } from './state';
import { TransactionDetail } from '@/types/transaction/TransactionDetail.interface';

export type Getters = {
    getTransaction(state: State): TransactionDetail;
}

export const getters: GetterTree<State, RootState> & Getters = {
    getTransaction: (state) => state.transaction,
};
