import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const TheContainer = () => import("@/layout/container/Container.vue");

const ProductIndex = () => import("@/views/pages/product/Index.vue");
const ProductDetail = () => import("@/views/pages/product/Details.vue");
const ProductSearch = () => import("@/views/pages/product/Search.vue");

const OrderHistoryIndex = () => import("@/views/pages/history/Index.vue");
const OrderHistoryDetail = () => import("@/views/pages/history/Details.vue");

const CheckoutIndex = () => import("@/views/pages/checkout/Index.vue");

const TransactionIndex = () => import("@/views/pages/transaction/Index.vue");

const LineRedirect = () => import("@/views/pages/line-redirect/Index.vue");

const ReturnIndex = () => import("@/views/pages/return/Index.vue");
const ReturnRequest = () => import("@/views/pages/return/ReturnForm.vue");


const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Line Redirect",
    component: LineRedirect,
    meta: { requiresAuth: true },
  },
  {

    path: "/:initialId",
    name: "Index",
    component: TheContainer,
    meta: { requiresAuth: true },
    children: [
      {
        path: "/:initialId",
        name: "Transaction",
        component: TransactionIndex,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/:initialId/product",
        name: "Product",
        component: ProductIndex,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/:initialId/product/detail/:id",
        name: "Product Detail",
        component: ProductDetail,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/:initialId/product/:category",
        name: "Product Category",
        component: ProductIndex,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/:initialId/search",
        name: "Product Search",
        component: ProductSearch,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/:initialId/order/history",
        name: "Order history",
        component: OrderHistoryIndex,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/:initialId/order/history/detail/:id",
        name: "Order history Detail",
        component: OrderHistoryDetail,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/:initialId/checkout",
        name: "Checkout",
        component: CheckoutIndex,
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/:initialId/return",
        name: "Return",
        component: ReturnIndex,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/:initialId/return/request/:id",
        name: "Return Request",
        component: ReturnRequest,
        meta: {
          requiresAuth: true,
        },
      },
    ],

  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
