import { User } from "@/types/user/User.interface";

export type State = {
    user: User,
    isUserNeedConsent: boolean
}
export const state: State = {
    user: {
        adminMode: false,
        birthday: "",
        chatbotSettingId: 0,
        createdTime: "",
        firstname: "",
        genderId: 0,
        isRequestTaxInvoice: false,
        lastname: "",
        memberTypeId: 0,
        profilePictureUrl: "",
        sameAddress: false,
        socialName: "",
        socialReferenceId: "",
        socialSourceId: 0,
        telephone: "",
        theOneCardRef: false,
        userGUID: ""
    },
    isUserNeedConsent: true
};
