import { Cart } from "@/types/cart/Cart.interface";


export type State = {
    cart: Cart,
    isShowCartPolicy: boolean,
    freeProductDisableFlage: boolean,
    refreshCart: boolean
}

export const state: State = {
    cart: {
        countFreeProduct: 0,
        discount: 0,
        discountPromotion: 0,
        discountShipping: 0,
        distance: 0,
        freeProducts: [],
        grandTotal: 0,
        isFreeProduct: false,
        productDiscount: [],
        products: [],
        promotions: [],
        saleChannel: 0,
        shippingCost: 0,
        total: 0,
        totalQuantity: 0,
        weightTotal: 0,
        postId: ""
    },
    isShowCartPolicy: false,
    freeProductDisableFlage: false,
    refreshCart: false
};
