import { createStore } from 'vuex';

// TODO: How to surpass cyclical dependency linting errors cleanly?
import { store as brand, BrandsStore, State as BrandState } from '@/store/modules/brand';
import { store as global, GlobalStore, State as GlobalState } from '@/store/modules/global';
import { store as transaction, TransactionStore, State as TransactionState } from '@/store/modules/transaction';
import { store as user, UserStore, State as UserState } from '@/store/modules/user';
import { store as category, CategoryStore, State as CategoryState } from '@/store/modules/category';
import { store as cart, CartStore, State as CartState } from '@/store/modules/cart';

export type RootState = {
  brand: BrandState;
  global: GlobalState,
  transaction: TransactionState,
  user: UserState,
  category: CategoryState,
  cart: CartState
};

export type Store = BrandsStore<Pick<RootState, 'brand'>> & GlobalStore<Pick<RootState, 'global'>>
  & TransactionStore<Pick<RootState, 'transaction'>> & UserStore<Pick<RootState, 'user'>>
  & CategoryStore<Pick<RootState, 'category'>> & CartStore<Pick<RootState, 'cart'>>


export const store = createStore({
  modules: {
    brand,
    global,
    transaction,
    user,
    category,
    cart
  },
});

export function useStore(): Store {
  return store as Store;
}
