
import ResponseData from "@/types/response/Response.interface"
import axios from "../instance/AxiosInstance"
import { Category, CategoryResponse } from "@/types/category/Category.interface"


export default class CategoryService {
    async getCategory(brandId: number): Promise<Category[]> {
        return await axios.get('/Category?brandId=' + brandId)
            .then((res: ResponseData<CategoryResponse>) => {
                if (res.data.result == 1) {
                    return res.data.detail as Category[]
                } else {
                    throw new Error(res.data.message);
                }
            }).catch(error => {
                if (error.response) {
                    throw ("ไม่สามารถดำเนินการได้ขณะนี้ กรุณาลองใหม่อีกครั้ง");
                } else {
                    throw error
                }
            })
    }

}
