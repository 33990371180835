import { Brand } from "@/types/brand/Brand.interface";

export type State = {
    brand: Brand;
}

export const state: State = {
    brand: {
        branchId: 0,
        brandId: 0,
        colorThemeMain: "transparent",
        colorThemeSecondary: "transparent",
        facebookQrCodeUrl: "",
        facebookUrl: "",
        favicon: "",
        isStoreFulfillment: 0,
        lineLiffId: "",
        lineOAId: "",
        lineUrl: "",
        loginChannelId: "",
        loginSecretKey: "",
        logo: "",
        name: "",
        pageId: "",
        qrCodeUrl: "",
        socialId: "",
        igId: ""
    }
};
